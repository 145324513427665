import regexUtil from '@/utils/regex';
import { createValidationObjectSchema } from '@/utils/validations';

export const AccountSchema = createValidationObjectSchema({
  email: {
    format: 'email',
    required: true,
  },
  password: {
    required: true,
    match: [
      regexUtil.password,
      'Password must be at least 8 characters, has a Uppercase, lowercase, numbers, and/or special char., (i.e., Password123!)',
    ],
  },
  password_confirmation: {
    required: true,
    oneOf: ['password', 'Passwords must match'],
  },
});

export const AuthSchema = createValidationObjectSchema({
  email: {
    format: 'email',
    required: true,
  },
  password: {
    required: true,
    match: [
      regexUtil.password,
      'Password at least 8 characters, has a Uppercase, lowercase, numbers, special char., (i.e., Password123!)',
    ],
  },
});

export const ValidEmail = createValidationObjectSchema({
  email: {
    format: 'email',
    required: true,
    match: [regexUtil.email, 'Please enter a valid email'],
  },
});

export const ResetPassword = createValidationObjectSchema({
  password: {
    required: true,
    match: [
      regexUtil.password,
      //'Password at least 8 characters, has a Uppercase, lowercase, numbers, special char., (i.e., Password123!)',
      'Please enter a valid password.',
    ],
  },
  password_confirmation: {
    required: true,
    oneOf: ['password', 'Confirm Password did not match password'],
  },
  // mobile_number: {
  //   match: [regexUtil.mobile['generic'], 'Mobile number is not a valid number'],
  //   required: true,
  // },
});

export const ContactSupportSchema = createValidationObjectSchema({
  email: {
    format: 'email',
    required: false,
    match: [regexUtil.email, 'Please enter a valid email'],
  },
  message: {
    required: true,
  },
});
