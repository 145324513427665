import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/views/v2/assets/images/logo/tenant-hub-logo-regular.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: _normalizeClass(_ctx.containerClass),
    src: _imports_0,
    alt: ""
  }, null, 2))
}