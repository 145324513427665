
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { Form } from 'vee-validate';

import toasts from '@/utils/toasts';

import { User } from '@/models/UserModel';
import { AccountSchema } from '@/schemas/common.schemas';
import { Getters, Actions } from '@/store/enums/AuthEnums';
import router from '@/router';
import FormField from '@/components/forms/FormField.vue';

const initialValues = {} as unknown as User;

export default defineComponent({
  name: 'sign-up-form',
  emits: ['userCreated'],
  components: {
    FormField,
    Form,
  },
  data: () => ({
    form: {} as typeof Form,
    formData: initialValues,
    loading: false,
    submitButton: {} as HTMLElement,
    agreed: false,
  }),
  mounted() {
    // setup the form reference
    this.form = this.$refs.registrationForm as typeof Form;

    this.submitButton = this.$refs.submitButton as HTMLElement;
  },
  computed: {
    ...mapGetters({
      actionErrors: Getters.GET_AUTH_USER_ACTION_ERROR,
    }),

    formSchema() {
      return AccountSchema;
    },
  },
  methods: {
    ...mapActions({
      register: Actions.REGISTER,
    }),

    resetForm() {
      // Reset the form validation and values
      this.form.resetForm();

      // Reset the form values
      this.formData = initialValues;
    },

    setFieldValue(field, value) {
      if (!this.form.setFieldValue) return;

      this.form.setFieldValue(field, value);
    },

    async submitRegistrationForm(values) {
      if (this.loading) return;
      this.loading = true;

      const username =
        typeof (await this.$route.params?.pathMatch) !== 'undefined' &&
        this.$route.params?.pathMatch?.length > 0
          ? await this.$route.params?.pathMatch[0]
          : null;

      this.formData = values;
      this.formData.referral = username as any;

      this.register(this.formData)
        .then(() => {
          this.loading = false;
          this.resetForm();
          toasts.success(
            'Account successfully created! Please check email to verify account.',
            () => {
              router.push({ name: 'account-verification' });
            }
          );
        })
        .catch(() => {
          this.loading = false;
          if (this.actionErrors) {
            const { errors, message } = this.actionErrors;
            toasts.error(errors, message);
          }
        });
    },
    validateFormField(field) {
      if (!this.form.validateField) return;

      this.form.validateField(field);
    },
  },
});
