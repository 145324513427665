
import { createApp, defineComponent } from 'vue';
import { VueReCaptcha, useReCaptcha } from 'vue-recaptcha-v3';

const googleRecaptchaComponent = {
  setup() {
    const reCaptchaInstance = useReCaptcha();

    const recaptcha = async () => {
      await reCaptchaInstance?.recaptchaLoaded();
      const token = await reCaptchaInstance?.executeRecaptcha('submit');

      return token;
    };

    return {
      recaptcha,
    };
  },
  template: '',
};

createApp(googleRecaptchaComponent).use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLERECAPTCHA_SITE_KEY,
  alterDomain: false,
  autoHideBadge: true,
});

export default defineComponent(googleRecaptchaComponent);
